<template>
  <modal-2 size="md" ref="modal">
    <div class="section" v-if="section">
      <edit-section-name
        :value="section.name"
        :sectionId="sectionId"
      />

      <div class="btn-container">
        <custom-button
          type="button"
          variant="secondary"
          :fullWidth="true"
          @click="onSectionDelete"
        >
          <font-awesome-icon icon="trash-alt" slot="icon" />
          Eliminar Dimensión
        </custom-button>
        <custom-button
          type="button"
          variant="secondary"
          :fullWidth="true"
          @click="batchAddQuestions"
        >
          <font-awesome-icon icon="plus" slot="icon" />
          Carga Masiva
        </custom-button>
        <custom-button
          type="button"
          variant="secondary"
          :fullWidth="true"
          @click="addingQuestion = true"
        >
          <font-awesome-icon icon="plus" slot="icon" />
          Agregar Pregunta
        </custom-button>
      </div>

      <div>
        <question-card
          v-if="addingQuestion"
          :isNewQuestion="true"
          :section="{ id: sectionId, name: section.name }"
          @cancel="addingQuestion = false"
        />

        <question-card
          v-for="(question, index) in sectionQuestions"
          :key="index"
          :question="question"
          :isEditable="section.isEditable"
          :section="{ id: sectionId, name: section.name }"
          :index="index"
          @delete="onQuestionDelete"
        />
      </div>

      <batch-add-questions ref="batchAddModal" />

      <confirm-dialogue ref="confirmDialogue" />
    </div>

    <loading-screen v-else />
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import ConfirmDialogue from "../../components/ConfirmDialogue.vue";
import CustomButton from "../../components/CustomButton.vue";
import LoadingScreen from "../../components/LoadingScreen.vue";
import Modal2 from "../../components/Modal2.vue";
import QuestionCard from "../../components/QuestionCard.vue";
import BatchAddQuestions from "./BatchAddQuestions.vue";
import EditSectionName from "./EditSectionName.vue";

export default {
  name: "SectionModal",

  components: {
    QuestionCard,
    CustomButton,
    ConfirmDialogue,
    EditSectionName,
    LoadingScreen,
    BatchAddQuestions,
    Modal2
  },

  data: () => ({
    sectionId: "",

    addingQuestion: false
  }),

  computed: {
    ...mapState("sections", ["sections", "questions"]),

    section() {
      return this.sections.find(s => s.id === this.sectionId);
    },

    sectionQuestions() {
      return this.questions.filter(q => q.section.id === this.sectionId);
    }
  },

  methods: {
    ...mapActions("sections", [
      "fetchSection",
      "deleteQuestion",
      "fetchSectionQuestions",
      "deleteSection"
    ]),

    ...mapMutations(["setAlert"]),

    batchAddQuestions() {
      this.$refs.batchAddModal.open({
        id: this.sectionId,
        name: this.section.name
      });
    },

    async onQuestionDelete(id) {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Eliminar Pregunta",
        message: "¿Estás seguro que quieres eliminar esta pregunta?",
        isDestructive: false,
        okButton: "Sí, borrar",
        cancelButton: "No, volver"
      });

      if (ok) {
        try {
          await this.deleteQuestion({
            id,
            sectionId: this.sectionId
          });

          this.setAlert({
            state: "success",
            message: "Se eliminó la pregunta"
          });
        } catch (err) {
          this.setAlert({
            state: "error",
            message: "Ocurrió un error, por favor inténtelo nuevamente"
          });
        }
      }
    },

    async onSectionDelete() {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Eliminar Dimensión",
        message: "¿Estás seguro que quieres eliminar esta dimensión?",
        isDestructive: false,
        okButton: "Sí, borrar",
        cancelButton: "No, volver"
      });

      if (!ok) return;

      try {
        await this.deleteSection({
          id: this.section.id,
          questionIds: this.section.questionIds
        });

        this.setAlert({
          state: "success",
          message: "Dimensión eliminada"
        });

        this.close();
      } catch (err) {
        console.log(err);
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtalo nuevamente"
        });
      }
    },

    async open(sectionId) {
      this.sectionId = sectionId;

      this.$refs.modal.open();

      if (!this.section) await this.fetchSection(this.sectionId);

      if (!this.sectionQuestions.length) {
        await this.fetchSectionQuestions(this.sectionId);
      }
    },

    close() {
      this.$refs.modal.close();
    }
  }
};
</script>

<style scoped>
.section {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  align-content: flex-start;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
}

.btn-container {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}
</style>
