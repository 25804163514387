<template>
  <div v-if="!isEditing" class="flex section-name">
    <h2>{{ value }}</h2>
    <icon-button :icon="editIcon" label="Editar" variant="text" @click="edit" />
  </div>

  <ValidationObserver tag="div" v-else v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)" class="flex">
      <custom-input v-model="name" rules="required" />

      <custom-button
        variant="secondary"
        type="button"
        @click="isEditing = false"
      >
        Cancelar
      </custom-button>

      <custom-button variant="primary" type="submit" :disabled="isLoading">
        {{ isLoading ? "Guardando..." : "Guardar" }}
      </custom-button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { ValidationObserver } from "vee-validate";

import IconButton from "../../components/IconButton.vue";
import CustomButton from "../../components/CustomButton.vue";
import CustomInput from "../../components/CustomInput.vue";

export default {
  components: {
    IconButton,
    ValidationObserver,
    CustomButton,
    CustomInput
  },

  props: {
    value: String,
    sectionId: String
  },

  data: () => ({
    editIcon: faEdit,
    isEditing: false,
    isLoading: false,
    name: ""
  }),

  methods: {
    ...mapActions("sections", ["updateSectionName"]),

    ...mapMutations(["setAlert"]),

    edit() {
      this.isEditing = true;
      this.name = this.value;
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        await this.updateSectionName({
          id: this.sectionId,
          name: this.name
        });

        this.isEditing = false;

        this.setAlert({
          state: "success",
          message: "Se actualizó el nombre de la dimensión"
        });
      } catch (error) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.section-name .input .field {
  font-size: var(--lengthLg1);
  font-weight: var(--semi-bold);
}

.flex {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}
</style>
