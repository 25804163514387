<template>
  <modal-2 ref="modal">
    <template slot="title">Ordenar Preguntas</template>
    <custom-form @submit="onSubmit">
      <sortable-list
        v-model="questionsCopy"
        lockAxis="y"
        :useDragHandle="true"
        :locktoContainerEdges="true"
      >
        <sortable-item
          v-for="(question, index) in questionsCopy"
          :key="question.id"
          :index="index"
        >
          <article class="question">
            <span class="question__index">{{ `Pregunta ${index + 1}` }}</span>
            <p class="question__text">{{ question.text }}</p>
          </article>
        </sortable-item>
      </sortable-list>

      <template slot="buttons">
        <custom-button
          variant="secondary"
          @click="onCancel"
          :disabled="isUploading"
        >
          Cancelar
        </custom-button>

        <custom-button variant="primary" type="submit" :disabled="isUploading">
          {{ isUploading ? "Guardando..." : "Guardar Orden" }}
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomButton from "../../components/CustomButton.vue";
import CustomForm from "../../components/CustomForm.vue";
import Modal2 from "../../components/Modal2.vue";
import SortableItem from "../../components/SortableItem.vue";
import SortableList from "../../components/SortableList.vue";

export default {
  name: "SortQuestionsModal",

  components: { Modal2, CustomForm, CustomButton, SortableList, SortableItem },

  data: () => ({
    questionsCopy: [],
    isUploading: false
  }),

  computed: {
    ...mapState("sections", ["questions"])
  },

  methods: {
    ...mapActions("sections", ["fetchQuestions", "updateQuestionsOrder"]),

    ...mapMutations(["setAlert"]),

    async open() {
      this.$refs.modal.open();

      await this.fetchQuestions();

      this.questionsCopy = this.questions.map(({ id, text }) => ({ id, text }));
    },

    close() {
      this.$refs.modal.close();
    },

    onCancel() {
      this.questionsCopy = [];
      this.close();
    },

    async onSubmit() {
      try {
        this.isUploading = true;

        await this.updateQuestionsOrder(
          this.questionsCopy.map((q, i) => ({ ...q, index: i }))
        );

        this.onCancel();

        this.setAlert({
          state: "success",
          message: "Orden actualizado"
        });
      } catch (error) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtalo nuevamente"
        });

        console.log(error);
      } finally {
        this.isUploading = false;
      }
    }
  }
};
</script>

<style scoped>
.question {
  flex-grow: 1;
  padding: var(--lengthSm2);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.question__index {
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--medium);
}

.question__text {
  margin-top: var(--lengthSm1);
  color: var(--fontColor2);
  font-size: var(--lengthSm3);
  font-weight: var(--medium);
}

.list-item {
  align-items: center;
}
</style>
