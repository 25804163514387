<template>
  <article class="card">
    <Checkbox :modelValue="isEnpsEnabled" @change="onChange" />
    <component
      :is="isEnpsEnabled ? 'router-link' : 'div'"
      :to="isEnpsEnabled ? '/dimensions/enps' : ''"
      :class="{ inactive: !isEnpsEnabled }"
    >
      <h4>{{ name }}</h4>
    </component>

    <div v-if="isLoading" class="loading-overlay">
      <LoadingSpinner size="md" />
    </div>
  </article>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Checkbox from "./Checkbox.vue";
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  name: "EnpsSectionCard",

  components: { Checkbox, LoadingSpinner },

  props: {
    name: {
      required: true,
      type: String
    }
  },

  data: () => ({
    isLoading: false
  }),

  computed: {
    ...mapState({
      isEnpsEnabled: state => state.survey.isEnpsEnabled
    })
  },

  methods: {
    ...mapActions(["updateEnpsActivation"]),

    ...mapMutations(["setAlert"]),

    async onChange(active) {
      try {
        this.isLoading = true;
        await this.updateEnpsActivation(active);

        this.setAlert({
          state: "success",
          message: "Se actualizó correctamente"
        });
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.card {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  text-align: start;
  padding: var(--lengthMd2);
  background-color: var(--mainColor2);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.card h4 {
  color: white;
}

.inactive {
  opacity: 0.3;
  cursor: not-allowed;
}

.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: grid;
  place-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
