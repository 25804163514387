<template>
  <modal-2 size="md" ref="modal">
    <template slot="title">Carga Masiva de Preguntas</template>

    <div v-if="!questions.length">
      <p>
        Aquí podrás agregar preguntas masivamente desde un archivo CSV.
      </p>

      <file-uploader @input="onFileUpload" />
    </div>

    <custom-form v-else @submit="onSubmit">
      <div class="table">
        <span class="table__header">Pregunta</span>
        <span class="table__header">Abierta</span>
        <span class="table__header"></span>
        <template v-for="(question, index) in questions">
          <span class="table__cell" :key="`text-${index}`">
            <custom-input v-model="question.text" rules="required" />
          </span>
          <span class="table__cell" :key="`is-open-${index}`">
            <checkbox v-model="question.isOpenQuestion" />
          </span>
          <span class="table__cell" :key="`del-btn-${index}`">
            <icon-button variant="text" icon="times" />
          </span>
        </template>
      </div>

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="onCancel">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary" :disabled="isLoading">
          {{ isLoading ? "Agregando..." : "Agregar" }}
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import Papa from "papaparse";

import CustomButton from "../../components/CustomButton.vue";
import CustomForm from "../../components/CustomForm.vue";
import FileUploader from "../../components/FileUploader.vue";
import Modal2 from "../../components/Modal2.vue";
import CustomInput from "../../components/CustomInput.vue";
import Checkbox from "../../components/Checkbox.vue";
import IconButton from "../../components/IconButton.vue";

export default {
  name: "BatchAddModal",

  components: {
    Modal2,
    FileUploader,
    CustomForm,
    CustomButton,
    CustomInput,
    IconButton,
    Checkbox
  },

  data: () => ({
    questions: [],
    section: {},
    isLoading: false
  }),

  methods: {
    ...mapActions("sections", ["addQuestions"]),

    ...mapMutations(["setAlert"]),

    open(section) {
      this.section = section;
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    },

    onFileUpload(file) {
      if (!file) return;

      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: results => {
          if (!results) return;

          results.data.forEach(q => this.questions.push({ text: q[0] }));
        }
      });
    },

    onCancel() {
      this.questions = [];
      this.section = {};
      this.close();
    },

    deleteQuestion(index) {
      this.questions[index] = {};
      this.questions.splice(index, 1);
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        await this.addQuestions({
          questions: this.questions,
          section: this.section
        });

        this.setAlert({
          state: "success",
          message: "Se agregaron las preguntas"
        });

        this.questions = [];
        this.close();
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor inténtelo nuevamente"
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
p {
  margin-bottom: var(--lengthMd1);
  text-align: center;
}

.table {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: stretch;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
}

.table__header {
  color: var(--fontColor3);
  font-size: 0.75rem;
  font-weight: var(--semi-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.table__header:first-child {
  border-top-left-radius: var(--lengthSm2);
}

.table__header:last-child {
  border-top-right-radius: var(--lengthSm2);
}

.table__header,
.table__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--lengthSm3);
  font-weight: var(--medium);
  text-align: start;
  white-space: nowrap;
}

.table__cell {
  border-top: 1px solid var(--grayColor2);
}
</style>
