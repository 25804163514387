<template>
  <div>
    <view-hero>
      <template slot="title">Dimensiones</template>
      <template slot="description">
        Puedes elegir las mejores dimensiones y preguntas para tu organización.
        Crear, editar y gestionar de manera adecuada todas tus encuestas.
      </template>
    </view-hero>
    <template v-if="sections.length">
      <div class="btn-container">
        <custom-button
          variant="secondary"
          @click="$refs.sortQuestionsModal.open()"
        >
          <font-awesome-icon slot="icon" :icon="icons.sort" />
          Ordenar Preguntas
        </custom-button>

        <custom-button variant="primary" @click="$refs.newSectionModal.open()">
          <font-awesome-icon slot="icon" icon="plus" />
          Nueva Dimensi&oacute;n
        </custom-button>
      </div>

      <div class="cards-container">
        <template v-for="section in sections">
          <EnpsSectionCard
            v-if="section.id === 'enps'"
            :key="section.id"
            :name="section.name"
          />

          <SectionCard
            v-else
            :key="section.id"
            :sectionTitle="section.name"
            :numberOfQuestions="section.questionIds.length"
            @click="onSectionClick(section.id)"
          />
        </template>
      </div>

      <div class="flex justify-between">
        <span class="total"
          >Total Dimensiones:
          {{ sections.length + (isEnpsEnabled ? 0 : -1) }}</span
        >
        <span class="total">
          Total Preguntas:
          {{
            sections.reduce(
              (amount, s) => amount + s.questionIds.length,
              isEnpsEnabled ? 0 : -1
            )
          }}
        </span>
      </div>
    </template>

    <empty-screen
      v-else
      :icon="icons.emptyScreen"
      @ctaClick="$refs.newSectionModal.open()"
    >
      <template slot="title">No hay Dimensiones</template>
      <template slot="description">
        Presiona el botón inferior para agregar tu primer grupo de preguntas.
      </template>
      <template slot="btnText">Nueva Dimensión</template>
    </empty-screen>

    <new-section-modal ref="newSectionModal" />

    <sort-questions-modal ref="sortQuestionsModal" />

    <section-modal ref="sectionModal" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { faLayerGroup, faSort } from "@fortawesome/free-solid-svg-icons";

import CustomButton from "@/components/CustomButton.vue";
import SectionCard from "@/components/SectionCard.vue";
import NewSectionModal from "./NewSectionModal.vue";
import EmptyScreen from "../EmptyScreen.vue";
import SortQuestionsModal from "./SortQuestionsModal.vue";
import SectionModal from "./SectionModal.vue";
import ViewHero from "../../components/ViewHero.vue";
import EnpsSectionCard from "../../components/EnpsSectionCard.vue";

export default {
  name: "sections",

  components: {
    CustomButton,
    SectionCard,
    NewSectionModal,
    EmptyScreen,
    SortQuestionsModal,
    SectionModal,
    ViewHero,
    EnpsSectionCard
  },

  data: () => ({
    icons: {
      sort: faSort,
      emptyScreen: faLayerGroup
    }
  }),

  computed: {
    ...mapState({
      sections: state => state.sections.sections,
      active: state => state.survey.active,
      isEnpsEnabled: state => state.survey.isEnpsEnabled
    })
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions("sections", ["fetchSections"]),

    onSectionClick(id) {
      this.$refs.sectionModal.open(id);
    },

    editSection(section) {
      this.edittedSection = JSON.parse(JSON.stringify(section));
      this.newSectionModal = true;
    }
  },

  mounted() {
    this.fetchSections();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.btn-container {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.view {
  background-color: var(--grayColor1);
}

.list-item {
  align-items: center;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--lengthMd2);
}

.total {
  font-size: 0.8rem;
  color: var(--fontColor3);
}

.hero {
  background-image: url("../../assets/modules/sections.jpg");
}
</style>
