<template>
  <modal-2 ref="modal">
    <template slot="title">Agregar Dimensión</template>

    <custom-form @submit="onSubmit">
      <custom-input
        v-model="name"
        id="section-name"
        label="Nombre"
        rules="required"
      />

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="close">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary" :disabled="isLoading">
          {{ isLoading ? "Agregando..." : "Agregar" }}
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import CustomInput from "@/components/CustomInput.vue";
import CustomForm from "@/components/CustomForm.vue";
import CustomButton from "@/components/CustomButton.vue";
import Modal2 from "../../components/Modal2.vue";

export default {
  name: "NewSectionModal",

  components: {
    CustomForm,
    CustomInput,
    CustomButton,
    Modal2
  },

  data: () => ({
    name: "",
    isLoading: false
  }),

  methods: {
    ...mapActions("sections", ["addSection"]),

    ...mapMutations(["setAlert"]),

    async onSubmit() {
      try {
        this.isLoading = true;

        await this.addSection(this.name);

        this.setAlert({
          state: "success",
          message: "Dimensión Agregada"
        });

        this.close();
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: "Algo salió mal, por favor vuelve a intentarlo"
        });
      } finally {
        this.isLoading = false;
      }
    },

    open() {
      this.$refs.modal.open();
    },

    close() {
      this.name = "";
      this.$refs.modal.close();
    }
  }
};
</script>
